import moment from "moment";
import { awaitWrap } from '@/page/admin/company-management/staff-management/common/unit'
import consumer_flight_search_confirmationDetails from '@/lib/data-service/haolv-default/consumer_flight_search_confirmationDetails'
import consumer_air_ticket_cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue';
import wait_some_seconds from "nllib/src/common/wait_some_seconds";
import Q from 'q'
export default {
    data() {
        return {
            loading: false,
            orderNo: '',
            evectionNo: '',
            evectionType: 1,
            detail: {},
            milliseconds: 0,
            intervalFun: '',
            show: true,

            stopDataShow: false,
            stopData: [], // 经停信息
            stopDataLoading: false,

            pageType: '',// 1是创建订单页，2是支付页，3是改签页

            errorDialog: false,
            errorText: '',
        }
    },
    components: {PaymentMethodSelector000},
    created() {
    },
    mounted() {
    },
    async activated() {
        const __this = this
        this.orderNo = this.$route.query.orderNo
        this.evectionNo = this.$route.query.evectionNo
        this.evectionType = parseInt(this.$route.query.evectionType)
        this.pageType = Number(this.$route.query.pageType) || 2;
        await this.getDetail()
        const aPaymentMethodSelector000 = __this.$refs.aPaymentMethodSelector000;
        const orderForPayVos = [
            {
                "orderNo": this.orderNo,
                "businessContent": ""
            },
        ]
        aPaymentMethodSelector000.init({
            page_this: __this,
            //在这里传入订单详情的相关参数
            btn_pay: {
                text: `确认并支付`,
            },
            mock_data: {
                enable: false,
            },
            count_down: {
                enable: true,
                pay_remaining_seconds: this.detail.confirmationDetails[0].milliseconds,//支付剩余时间，以秒为单位
            },
            get_params() {
                const p = {
                    "orderForPayVos": orderForPayVos,
                    "payType":  __this.pageType !== 3 ? 1 : 2
                };
                return Q.resolve(p);
            },

            //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
            before_pay_event_handler() {
                // 机票单验价
                return consumer_admin_flight_checkFlightPay({orderNo: [__this.orderNo]}).then(res => {
                    return Q.resolve();
                }, error => {
                    // 200029  变价
                    __this.errorText = error.data.msg
                    __this.errorDialog = true
                    //__this.init()
                    return Q.reject()
                })
            },

            //支付成功事件处理方法
            pay_successful_event_handler() {
                console.log(`pay_successful_event_handler`);
                __this.$message({ type: "success", message: "支付成功！" });
                __this.$router.push({
                    name: 'admin-pay-success',
                })
            },
            //支付失败事件处理方法
            pay_failed_event_handler() {
                console.log(`pay_failed_event_handler`);
            },
            //取消订单按钮单击事件处理方法
            btn_cancel_order_click_event_handler(args) {
                const show_loading = args.show_loading;
                const hide_loading = args.hide_loading;
                const stop_count_down = args.stop_count_down;
                const enable_btn_pay = args.enable_btn_pay;
                const enable_btn_cancel_order = args.enable_btn_cancel_order;

                Q.when()
                    .then(function () {
                        show_loading();
                        return wait_some_seconds({
                            seconds: 2,
                        });
                    })
                    .then(function () {
                        console.log(`btn_cancel_order_click_event_handler`);
                        hide_loading();
                        stop_count_down();
                        enable_btn_pay({
                            enable: false,
                        });
                        enable_btn_cancel_order({
                            enable: false,
                        });
                    })
                    .catch(function (ex) {
                        if (ex) {
                            const enable_log_error = !ex.disable_log_error;
                            if (enable_log_error) {
                                console.error(ex);
                                console.trace();
                                if (ex.stack) {
                                    console.error(ex.stack);
                                }
                            }
                        }
                        return Q.reject(ex);
                    })
                ;
            },
            //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
            dialog_code_300052_btn_ok_click_event_handler() {
                console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                __this.$router.replace({
                    name: `admin-my-evection`,
                });
                return Q.resolve(true);
                // return true;
            },
        });
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        showMore() {
            this.show = !this.show
        },
        back() {
            this.errorDialog = false
            this.$router.go(-1)
        },
        async getDetail() {
            this.loading = true
            let [err, res] = await awaitWrap(consumer_flight_search_confirmationDetails({orderNo: this.orderNo}))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            this.detail = res.datas
            this.milliseconds = res.datas && res.datas.confirmationDetails ? res.datas.confirmationDetails[0].milliseconds * 1000 || 0 : 0
            clearInterval(this.intervalFun)
            if (this.milliseconds > 0) {
                this.setPayInterval()
            }
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds = this.milliseconds - 1000
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun)
                    // 超时未支付，进行取消订单
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            this.$message({
                type: "warning",
                message: '超时未支付，订单已自动取消'
            })
            consumer_air_ticket_cancelOrder({ orderNo: this.orderNo }).then((res) => {
                // 跳转订单详情
                this.$router.replace({
                    name: 'flight-order-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType
                    }
                })
            });
        },
    }
}
